import React from "react";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";
import FeatherIcon from 'feather-icons-react';

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FeatherIcons = () => {
    document.title = "Feather | Symox - React Admin & Dashboard Template";

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Icons" breadcrumbItem="Feather" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Examples</h4>
                                    <p className="card-title-desc">Use className <code> &lt;FeatherIcon icon=&quot;-**"&gt;&lt;/i&gt;</code></p>

                                </CardHeader>
                                <CardBody>
                                    <Row className="icon-demo-content">
                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="activity" /><span>activity</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="airplay" /><span>airplay</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="alert-circle" /><span>alert-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="alert-octagon" /><span>alert-octagon</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="alert-triangle" /><span>alert-triangle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="align-center" /><span>align-center</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="align-justify" /><span>align-justify</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="align-left" /><span>align-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="align-right" /><span>align-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="anchor" /><span>anchor</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="aperture" /><span>aperture</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="archive" /><span>archive</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-down-circle" /><span>arrow-down-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-down-left" /><span>arrow-down-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-down-right" /><span>arrow-down-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-down" /><span>arrow-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-left-circle" /><span>arrow-left-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-left" /><span>arrow-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-right-circle" /><span>arrow-right-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-right" /><span>arrow-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-up-circle" /><span>arrow-up-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-up-left" /><span>arrow-up-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-up-right" /><span>arrow-up-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="arrow-up" /><span>arrow-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="at-sign" /><span>at-sign</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="award" /><span>award</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bar-chart-2" /><span>bar-chart-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bar-chart" /><span>bar-chart</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="battery-charging" /><span>battery-charging</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="battery" /><span>battery</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bell-off" /><span>bell-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bell" /><span>bell</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bluetooth" /><span>bluetooth</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bold" /><span>bold</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="book-open" /><span>book-open</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="book" /><span>book</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="bookmark" /><span>bookmark</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="box" /><span>box</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="briefcase" /><span>briefcase</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="calendar" /><span>calendar</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="camera-off" /><span>camera-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="camera" /><span>camera</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cast" /><span>cast</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="check-circle" /><span>check-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="check-square" /><span>check-square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="check" /><span>check</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevron-down" /><span>chevron-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevron-left" /><span>chevron-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevron-right" /><span>chevron-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevron-up" /><span>chevron-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevrons-down" /><span>chevrons-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevrons-left" /><span>chevrons-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevrons-right" /><span>chevrons-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chevrons-up" /><span>chevrons-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="chrome" /><span>chrome</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="circle" /><span>circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="clipboard" /><span>clipboard</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="clock" /><span>clock</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud-drizzle" /><span>cloud-drizzle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud-lightning" /><span>cloud-lightning</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud-off" /><span>cloud-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud-rain" /><span>cloud-rain</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud-snow" /><span>cloud-snow</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cloud" /><span>cloud</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="code" /><span>code</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="codepen" /><span>codepen</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="codesandbox" /><span>codesandbox</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="coffee" /><span>coffee</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="columns" /><span>columns</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="command" /><span>command</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="compass" /><span>compass</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="copy" /><span>copy</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-down-left" /><span>corner-down-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-down-right" /><span>corner-down-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-left-down" /><span>corner-left-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-left-up" /><span>corner-left-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-right-down" /><span>corner-right-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-right-up" /><span>corner-right-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-up-left" /><span>corner-up-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="corner-up-right" /><span>corner-up-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="cpu" /><span>cpu</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="credit-card" /><span>credit-card</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="crop" /><span>crop</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="crosshair" /><span>crosshair</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="database" /><span>database</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="delete" /><span>delete</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="disc" /><span>disc</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="dollar-sign" /><span>dollar-sign</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="download-cloud" /><span>download-cloud</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="download" /><span>download</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="droplet" /><span>droplet</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="edit-2" /><span>edit-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="edit-3" /><span>edit-3</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="edit" /><span>edit</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="external-link" /><span>external-link</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="eye-off" /><span>eye-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="eye" /><span>eye</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="facebook" /><span>facebook</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="fast-forward" /><span>fast-forward</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="feather" /><span>feather</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="figma" /><span>figma</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="file-minus" /><span>file-minus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="file-plus" /><span>file-plus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="file-text" /><span>file-text</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="file" /><span>file</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="film" /><span>film</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="filter" /><span>filter</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="flag" /><span>flag</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="folder-minus" /><span>folder-minus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="folder-plus" /><span>folder-plus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="folder" /><span>folder</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="framer" /><span>framer</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="frown" /><span>frown</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="gift" /><span>gift</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="git-branch" /><span>git-branch</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="git-commit" /><span>git-commit</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="git-merge" /><span>git-merge</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="git-pull-request" /><span>git-pull-request</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="github" /><span>github</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="gitlab" /><span>gitlab</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="globe" /><span>globe</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="grid" /><span>grid</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="hard-drive" /><span>hard-drive</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="hash" /><span>hash</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="headphones" /><span>headphones</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="heart" /><span>heart</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="help-circle" /><span>help-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="hexagon" /><span>hexagon</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="home" /><span>home</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="image" /><span>image</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="inbox" /><span>inbox</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="info" /><span>info</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="instagram" /><span>instagram</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="italic" /><span>italic</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="key" /><span>key</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="layers" /><span>layers</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="layout" /><span>layout</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="life-buoy" /><span>life-buoy</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="link-2" /><span>link-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="link" /><span>link</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="linkedin" /><span>linkedin</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="list" /><span>list</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="loader" /><span>loader</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="lock" /><span>lock</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="log-in" /><span>log-in</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="log-out" /><span>log-out</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="mail" /><span>mail</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="map-pin" /><span>map-pin</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="map" /><span>map</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="maximize-2" /><span>maximize-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="maximize" /><span>maximize</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="meh" /><span>meh</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="menu" /><span>menu</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="message-circle" /><span>message-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="message-square" /><span>message-square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="mic-off" /><span>mic-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="mic" /><span>mic</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="minimize-2" /><span>minimize-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="minimize" /><span>minimize</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="minus-circle" /><span>minus-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="minus-square" /><span>minus-square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="minus" /><span>minus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="monitor" /><span>monitor</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="moon" /><span>moon</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="more-horizontal" /><span>more-horizontal</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="more-vertical" /><span>more-vertical</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="mouse-pointer" /><span>mouse-pointer</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="move" /><span>move</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="music" /><span>music</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="navigation-2" /><span>navigation-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="navigation" /><span>navigation</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="octagon" /><span>octagon</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="package" /><span>package</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="paperclip" /><span>paperclip</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="pause-circle" /><span>pause-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="pause" /><span>pause</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="pen-tool" /><span>pen-tool</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="percent" /><span>percent</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-call" /><span>phone-call</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-forwarded" /><span>phone-forwarded</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-incoming" /><span>phone-incoming</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-missed" /><span>phone-missed</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-off" /><span>phone-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone-outgoing" /><span>phone-outgoing</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="phone" /><span>phone</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="pie-chart" /><span>pie-chart</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="play-circle" /><span>play-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="play" /><span>play</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="plus-circle" /><span>plus-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="plus-square" /><span>plus-square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="plus" /><span>plus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="pocket" /><span>pocket</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="power" /><span>power</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="printer" /><span>printer</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="radio" /><span>radio</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="refresh-ccw" /><span>refresh-ccw</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="refresh-cw" /><span>refresh-cw</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="repeat" /><span>repeat</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="rewind" /><span>rewind</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="rotate-ccw" /><span>rotate-ccw</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="rotate-cw" /><span>rotate-cw</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="rss" /><span>rss</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="save" /><span>save</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="scissors" /><span>scissors</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="search" /><span>search</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="send" /><span>send</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="server" /><span>server</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="settings" /><span>settings</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="share-2" /><span>share-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="share" /><span>share</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="shield-off" /><span>shield-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="shield" /><span>shield</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="shopping-bag" /><span>shopping-bag</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="shopping-cart" /><span>shopping-cart</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="shuffle" /><span>shuffle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="sidebar" /><span>sidebar</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="skip-back" /><span>skip-back</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="skip-forward" /><span>skip-forward</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="slack" /><span>slack</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="slash" /><span>slash</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="sliders" /><span>sliders</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="smartphone" /><span>smartphone</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="smile" /><span>smile</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="speaker" /><span>speaker</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="square" /><span>square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="star" /><span>star</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="stop-circle" /><span>stop-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="sun" /><span>sun</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="sunrise" /><span>sunrise</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="sunset" /><span>sunset</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="tablet" /><span>tablet</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="tag" /><span>tag</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="target" /><span>target</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="terminal" /><span>terminal</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="thermometer" /><span>thermometer</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="thumbs-down" /><span>thumbs-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="thumbs-up" /><span>thumbs-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="toggle-left" /><span>toggle-left</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="toggle-right" /><span>toggle-right</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="tool" /><span>tool</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="trash-2" /><span>trash-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="trash" /><span>trash</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="trello" /><span>trello</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="trending-down" /><span>trending-down</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="trending-up" /><span>trending-up</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="triangle" /><span>triangle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="truck" /><span>truck</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="tv" /><span>tv</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="twitch" /><span>twitch</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="twitter" /><span>twitter</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="type" /><span>type</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="umbrella" /><span>umbrella</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="underline" /><span>underline</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="unlock" /><span>unlock</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="upload-cloud" /><span>upload-cloud</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="upload" /><span>upload</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="user-check" /><span>user-check</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="user-minus" /><span>user-minus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="user-plus" /><span>user-plus</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="user-x" /><span>user-x</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="user" /><span>user</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="users" /><span>users</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="video-off" /><span>video-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="video" /><span>video</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="voicemail" /><span>voicemail</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="volume-1" /><span>volume-1</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="volume-2" /><span>volume-2</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="volume-x" /><span>volume-x</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="volume" /><span>volume</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="watch" /><span>watch</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="wifi-off" /><span>wifi-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="wifi" /><span>wifi</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="wind" /><span>wind</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="x-circle" /><span>x-circle</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="x-octagon" /><span>x-octagon</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="x-square" /><span>x-square</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="x" /><span>x</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="youtube" /><span>youtube</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="zap-off" /><span>zap-off</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="zap" /><span>zap</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="zoom-in" /><span>zoom-in</span>
                                        </Col>

                                        <Col xl={3} lg={4} sm={6}>
                                            <FeatherIcon icon="zoom-out" /><span>zoom-out</span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ div>
        </React.Fragment>
    );
};

export default FeatherIcons;
